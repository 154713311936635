<script setup lang="ts">
  import logoUrl from "#assets/kato.studio/logo.svg";
  import { onMounted } from 'vue';

  onMounted(() => {
    const elements = document.querySelectorAll("[data-scroll-effect]") as NodeListOf<HTMLElement>;
    const observerOptions: IntersectionObserverInit = {
      root: null, // viewport
      rootMargin: '0px',
      threshold: 0.15 // trigger when 15% of the element is in view
    };

    const animations: Record<string, (duration: string) => { in: Record<string, string>, out: Record<string, string> }> = {
      fadeIn: (duration: string) => ({
        in: {
          opacity: '1',
          transition: `all ${duration} cubic-bezier(0.5, 0, 0.75, 0);`,
        },
        out: {
          opacity: '0',
          transition: `all ${duration} cubic-bezier(0.5, 0, 0.75, 0);`,
        }
      }),
    };

    const observerCallback: IntersectionObserverCallback = (entries, observer) => {
      entries.forEach(entry => {
        const target = entry.target as HTMLElement;
        const inViewStyles = target.getAttribute('data-scroll-effect') || 'fadeIn-top,0.6s,0.2s';
        const properties: Record<string, string> = {};
        const [effect, duration, delay] = inViewStyles.split(',');
        const [name, direction] = effect.split('-');
        if (!name) console.log(`Animation [${name}] not found`);

        const getTransform = (direction: string, amount = '45px'): string => {
          switch (direction) {
            case 'top':
              return "translateY(50px)";
            case 'bottom':
              return "translateY(-50px)";
            case 'left':
              return "translateX(-50px)";
            case 'right':
              return "translateX(50px)";
            default:
              return "translateX(0px) translateY(0px)";
          }
        };

        properties.transitionDuration = duration || '0.3s';
        properties.transitionDelay = delay || '0s';

        const animation = animations[name](duration);
        if (entry.isIntersecting) {
          // Apply in-view styles
          const styles = { ...properties, ...animation.in };
          target.style.transform = getTransform('null');
          Object.keys(styles).forEach(key => {
            target.style[key as any] = styles[key];
          });
        } else {
          // Apply out-of-view styles
          const styles = { ...properties, ...animation.out };
          target.style.transform = getTransform(direction);
          Object.keys(styles).forEach(key => {
            target.style[key as any] = styles[key];
          });
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);
    elements.forEach(element => observer.observe(element));
  });
</script>
<template>
  <header class="relative body-font z-20">
    <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center justify-between">
      <a href="/" aria-label="kato logo link" class="flex title-font font-medium items-center mb-4 md:mb-0 h-[70px] w-[160px]">
        <img :src="logoUrl" alt="kato logo" />
      </a>
      <nav class="flex flex-wrap items-center text-base justify-center">
         <!-- TODO add multi page links -->
      </nav>
    </div>
  </header>
  <div class="layout">
    <slot />
  </div>
  <footer class="body-font pt-10">
    <hr class="opacity-50"/>
    <div class="container mx-auto flex-col md:flex-row flex justify-between px-5 pt-24 pb-8">
      <a href="/" aria-label="kato logo link" class="mu-auto mb-4 md:mb-0 h-[70px] w-[160px]">
        <img :src="logoUrl" alt="kato logo" />
      </a>
      <div class="mb-7">
        <p class="leading-relaxed">Kato Studio</p>
        <p class="text-zinc-400">| The partner that solves your digital problems, empowering your business to thrive, not just survive.</p>
        <hr class="opacity-20 my-2">
        <p class="text-zinc-400">&copy; 2024 Kato Studio. All rights reserved.</p>
      </div>
    </div>
    <component :is="'script'" defer src="https://code.iconify.design/iconify-icon/2.1.0/iconify-icon.min.js"/>
  </footer>
</template>
<style>
  /* see https://stackoverflow.com/questions/55206901/how-to-import-css-files-in-vue-3-child-components */
  @import "../../tailwind.css";
  body {
    margin: 0;
    font-family: sans-serif;
  }
  * {
    box-sizing: border-box;
  }
  a {
    text-decoration: none;
  }
  /* Set tailwinds primary colors to match brand colors */
  :root {
    --primary-50: #9561EF;
    --primary-100: #894FED;
    --primary-200: #702AE9;
    --primary-300: #5C16D4;
    --primary-400: #4C12AF;
    --primary: #4C12AF;
    --primary-500: #400F93;
    --primary-600: #340C78;
    --primary-700: #28095C;
    --primary-800: #1C0740;
    --primary-900: #100424;
    --primary-950: #0A0216;
    
    --secondary-50: #f3e5f5;
    --secondary-100: #e1bee7;
    --secondary-200: #ce93d8;
    --secondary-300: #ba68c8;
    --secondary-400: #ab47bc;
    --secondary: #9c27b0;
    --secondary-500: #9c27b0;
    --secondary-600: #8e24aa;
    --secondary-700: #7b1fa2;
    --secondary-800: #6a1b9a;
    --secondary-900: #4a148c;
    --secondary-950: #360d80;

    --accent-50: #fafafa;
    --accent-100: #f5f5f5;
    --accent-200: #eeeeee;
    --accent-300: #e0e0e0;
    --accent-400: #bdbdbd;
    --accent: #9e9e9e;
    --accent-500: #9e9e9e;
    --accent-600: #757575;
    --accent-700: #616161;
    --accent-800: #424242;
    --accent-900: #212121;
    --accent-950: #171717;
  }
  body {
    @apply bg-zinc-900 text-white;
  }
  @font-face {
    font-family: 'Chillax'; 
    src: url('/public/fonts/Chillax-Semibold.otf') format('opentype');
  }
  @font-face {
    font-family: 'Chillax-bold'; 
    src: url('/public/fonts/Chillax-Bold.otf') format('opentype');
  }
  .font-heading {
     font-family: 'Chillax-bold', serif;
  }
  .font-body, body {
    font-family: "Poppins", serif;
    font-optical-sizing: auto;
    font-weight: 400;
    font-style: normal;
  }
  .lines-bg-primary {
    background: repeating-linear-gradient(-45deg, var(--primary-400), var(--primary-400) 5px, transparent 5px, transparent 10px);
  }
</style>